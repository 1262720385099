import * as React from 'react';

import logoUrl from './logo.png';

export const CompanyLogo: React.FC<{
  width?: number;
  style?: React.CSSProperties;
}> = ({ style = {}, width = 75 }) => {
  return (
    <img
      src={logoUrl}
      width={width}
      style={{ alignSelf: 'center', opacity: 0.9, ...style }}
    />
  );
};
