import {
  ApartmentOutlined,
  BankOutlined,
  BellOutlined,
  DatabaseOutlined,
  FileSearchOutlined,
  MessageOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Feature } from '@shared/features';
import { useFeature } from '@web/common/useFeature';
import { Column, GrowingSpacer } from '@web/components/layout';
import OrganizationsModal from '@web/organizations/OrganizationsModal';
import { Typography } from 'antd';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { CompanyLogo } from './CompanyLogo';
import { OrganizationPicker } from './OrganizationPicker';
import { useOrganizationFilter } from './organizationFilter';
import { IF_MOBILE } from './responsive';

export const InternalSidebar: React.FC = () => {
  const { organizationTokenFilter } = useOrganizationFilter();
  const [showOrganizationsModal, setShowOrganizationsModal] =
    React.useState(false);
  const organizationPath = `/organizations/${organizationTokenFilter}`;

  return (
    <Container>
      <Column gap={18} style={{ height: '100%' }}>
        <SidebarLinks>
          <CompanyLogo style={{ marginBottom: 12 }} />
          {organizationTokenFilter && (
            <>
              <SidebarLink to={`${organizationPath}/details`}>
                <ShopOutlined /> Organization
              </SidebarLink>
              <SidebarLink to={`${organizationPath}/users`}>
                <ApartmentOutlined /> Users
              </SidebarLink>
              <SidebarLink to={`${organizationPath}/files`}>
                <DatabaseOutlined /> Documents
              </SidebarLink>
            </>
          )}
        </SidebarLinks>
        <GrowingSpacer />
        <OrganizationPicker
          onClick={() => {
            setShowOrganizationsModal(true);
          }}
        />
        <OrganizationsModal
          onClose={() => {
            setShowOrganizationsModal(false);
          }}
          open={showOrganizationsModal}
        />
      </Column>
    </Container>
  );
};

export const CustomerSidebar: React.FC = () => {
  const { booleanValue: announcementsEnabled } = useFeature(
    Feature.ANNOUNCEMENTS,
  );
  return (
    <Container>
      <Column gap={18} style={{ height: '100%' }}>
        <SidebarLinks>
          <SidebarLink to={`/chat`}>
            <MessageOutlined /> Ask a Question
          </SidebarLink>
          {announcementsEnabled && (
            <SidebarLink to={`/announcements`}>
              <BellOutlined /> Announcements
            </SidebarLink>
          )}
          <SidebarLink to={`/governance`}>
            <BankOutlined /> Governance
          </SidebarLink>
          <SidebarLink to={`/explore`}>
            <FileSearchOutlined /> Documents
          </SidebarLink>
        </SidebarLinks>
      </Column>
    </Container>
  );
};

const SidebarLink: React.FC<{
  to?: string;
  url?: string;
  children: React.ReactNode;
}> = ({ to, url, children }) =>
  url ? (
    <a href={url} target="_blank" rel="noreferrer" className="sidebar-link">
      <Typography.Text>{children}</Typography.Text>
    </a>
  ) : (
    <NavLink to={to} className="sidebar-link">
      <Typography.Text>{children}</Typography.Text>
    </NavLink>
  );

export const SIDEBAR_WIDTH = 240;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: ${SIDEBAR_WIDTH}px;

  ${IF_MOBILE} {
    padding: 24px 0;
  }
`;

const SidebarLinks = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;

  svg {
    margin-right: 5px;
  }

  .sidebar-link,
  .sidebar-title {
    cursor: pointer;
    border-radius: var(--default-border-radius);
    transition: all 0.2s linear;
    padding: 0 18px;
    white-space: nowrap;

    > span {
      margin: 0;
      display: flex;
      gap: 12px;
      align-items: center;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #666;

      svg {
        font-size: 18px;
        padding: 2px;
        margin: 0;
        color: #666;
      }
    }
  }

  .sidebar-link:hover {
    background-color: #f5f5f5;
  }

  .sidebar-link.active {
    > span {
      font-weight: 500;
      color: #000;
    }

    svg {
      color: #000;
    }
  }
`;
