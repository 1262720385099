import { OrganizationToken } from './organizations';
import { UserToken } from './types';

export enum Feature {
  SHOW_PROMPT_SOURCES = 'SHOW_PROMPT_SOURCES',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
}

export interface IFeatureValue {
  booleanValue?: boolean;
  stringValue?: string;
  numberValue?: number;
}

export interface IFeatureOverride extends IFeatureValue {
  feature: Feature;
  entityToken: UserToken | OrganizationToken;
  createdDate: Date;
  updatedDate: Date;
}

export const featureDefaults: Record<Feature, IFeatureValue> = {
  [Feature.SHOW_PROMPT_SOURCES]: {
    booleanValue: false,
  },
  [Feature.ANNOUNCEMENTS]: {
    booleanValue: false,
  },
};

export interface IFeatureDetailsResponse {
  featureDefaults: Record<Feature, IFeatureValue>;
  featureOverrides: Record<Feature, IFeatureOverride>;
}

export interface IUpdateFeatureOverridesRequest {
  overrides: Array<Partial<IFeatureOverride>>;
}
