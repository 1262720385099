import { OrganizationToken } from './organizations';
import { IUser, UserToken } from './types';

export type AnnouncementToken = `a_${string}`;
export interface IAnnouncement {
  token: AnnouncementToken;
  title: string;
  content?: string;
  authorToken: UserToken;
  author?: IUser;
  organizationToken?: OrganizationToken;
  state: AnnouncementState;
  publishedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export enum AnnouncementState {
  DRAFT = 'DRAFT',
  PUBLIC = 'PUBLIC',
}
