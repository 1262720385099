import {
  CloseCircleOutlined,
  EyeOutlined,
  FileSearchOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useFilesAndFolders } from '@client/FilesClient';
import { IFile } from '@shared/files';
import { OrganizationToken } from '@shared/organizations';
import { PageContent, usePage } from '@web/app/Page';
import { del, put } from '@web/common/api';
import { formatDate } from '@web/common/formatDate';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header1, Small, Text } from '@web/components/typography';
import { EditFileModal } from '@web/meetings/agenda_items/EditFileModal';
import { FileIcon } from '@web/topics/FileIcon';
import { Button, Skeleton, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { UploadChangeParam } from 'antd/es/upload';
import { Table, Upload, UploadFile } from 'antd/lib';
import { last } from 'lodash';
import * as React from 'react';

export const InternalFilesPage: React.FC = () => {
  const { navigate, organizationToken } = usePage<{
    organizationToken: OrganizationToken;
  }>();
  const { confirm, contextHolder } = useModalConfirm();
  const [editingFile, setEditingFile] = React.useState<IFile>(null);
  const [page, setPage] = React.useState(1);
  const { data, mutate: reloadFiles } = useFilesAndFolders(organizationToken);

  const handleRemoveFile = async (file: IFile) => {
    const confirmed = await confirm(
      'Do you wish to permanently remove this file?',
      { title: 'Delete File' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await del(`/files/${file.token}`);
      void message.success('Success');
      void reloadFiles();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleIndexFile = async (file: IFile) => {
    try {
      await put(`/search/index/files/${file.token}`);
      void message.success('Success');
      void reloadFiles();
    } catch (error) {
      void message.error('Error');
    }
  };

  const columns: ColumnsType<IFile> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (_, file) => {
        const extension = last(file.name.split('.'));

        return (
          <Row gap={12} style={{ alignItems: 'flex-start' }}>
            <FileIcon extension={extension} size={28} />
            <Column>
              <Text style={{ lineHeight: '18px', fontWeight: 500 }}>
                {file.name}
              </Text>
              <Small
                style={{
                  fontStyle: file.description ? undefined : 'italic',
                }}
              >
                {file.description ?? 'No description'}
              </Small>
              <Small
                style={{
                  color: '#888',
                  fontStyle: 'italic',
                }}
              >
                Uploaded by {file.owner.name} on {formatDate(file.createdDate)}
              </Small>
            </Column>
          </Row>
        );
      },
    },

    {
      title: 'Indexed',
      key: 'indexed',
      dataIndex: 'indexedDate',
      render: (indexedDate) =>
        indexedDate ? <Text>{formatDate(indexedDate)}</Text> : '',
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (_, file) => {
        return (
          <StopPropagation>
            <Row gap={8} style={{ justifyContent: 'flex-end' }}>
              <Tooltip title="Index document">
                <FileSearchOutlined
                  style={{ fontSize: 16, color: '#666' }}
                  onClick={() => {
                    void handleIndexFile(file);
                  }}
                />
              </Tooltip>
              <Tooltip title="View document">
                <a
                  href={`/api/files/${file.token}/${file.name}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <EyeOutlined
                    style={{ fontSize: 16, color: '#666' }}
                    onClick={() => {
                      void handleIndexFile(file);
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip title="Remove document">
                <CloseCircleOutlined
                  style={{ fontSize: 16, color: '#666' }}
                  onClick={() => {
                    void handleRemoveFile(file);
                  }}
                />
              </Tooltip>
            </Row>
          </StopPropagation>
        );
      },
    },
  ];

  return (
    <PageContent>
      <Row>
        <Header1>Documents</Header1>
        <GrowingSpacer />
        <UploadNewFile
          onUpload={() => {
            void reloadFiles();
          }}
          organizationToken={organizationToken}
        />
      </Row>
      {data ? (
        <Table
          rowKey="token"
          pagination={{
            total: data.files.length,
            onChange: setPage,
            current: page,
            pageSize: 50,
            showSizeChanger: false,
            showTotal: (total) => `${total} files`,
          }}
          columns={columns}
          dataSource={data.files}
          onRow={(file) => {
            return {
              onClick: () => {
                navigate(`${file.token}/details`);
              },
            };
          }}
        />
      ) : (
        <Skeleton />
      )}
      {contextHolder}
      {editingFile && (
        <EditFileModal
          file={editingFile}
          onClose={() => {
            setEditingFile(null);
          }}
          onSave={() => {
            setEditingFile(null);
            void reloadFiles();
          }}
        />
      )}
    </PageContent>
  );
};

const UploadNewFile: React.FC<{
  onUpload?: (newFile: IFile) => void;
  organizationToken: OrganizationToken;
}> = ({ onUpload, organizationToken }) => {
  const [uploading, setUploading] = React.useState(false);
  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    } else if (info.file.status === 'done') {
      onUpload?.(info.file.response);
      void message.success(`File uploaded`);
    } else if (info.file.status === 'error') {
      void message.error(`Error`);
    }

    setUploading(false);
  };
  return (
    <Upload
      name="file"
      onChange={handleUploadChange}
      action={`/api/files?organizationToken=${organizationToken}`}
      showUploadList={false}
      disabled={uploading}
    >
      <Button>
        {uploading ? <LoadingOutlined /> : <UploadOutlined />} Upload a file
      </Button>
    </Upload>
  );
};
