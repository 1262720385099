import { ArrowLeftOutlined } from '@ant-design/icons';
import { questionPrompt } from '@client/ChatClient';
import { IChatResponse } from '@shared/completions';
import { Feature } from '@shared/features';
import { mapByToken } from '@shared/mapByToken';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { Button, PrimaryButton } from '@web/components/Button';
import { Markdown } from '@web/components/Markdown';
import { Pane } from '@web/components/Pane';
import { Center, Column, Row, Spacer } from '@web/components/layout';
import { Header1, Header3, Text } from '@web/components/typography';
import { Tooltip, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';
import styled from 'styled-components';

export const ChatPage: React.FC = () => {
  const { booleanValue: showPromptSources } = useFeature(
    Feature.SHOW_PROMPT_SOURCES,
  );
  const { organization } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [prompt, setPrompt] = React.useState('');
  const [promptResponse, setPromptResponse] =
    React.useState<IChatResponse>(null);

  const handlePromptSubmit = async (prompt: string) => {
    if (prompt.length === 0) {
      setPromptResponse(null);
      return;
    }

    void message.loading('🤖 Thinking... beep bop beep...');
    setLoading(true);

    try {
      const response = await questionPrompt(prompt);
      setPromptResponse(response);
      message.destroy();
      setLoading(false);
    } catch (error) {
      await message.destroy();
      setLoading(false);
      void message.error('Error');
    }
  };

  const fileMap = promptResponse ? mapByToken(promptResponse.files) : new Map();

  return (
    <PageContent>
      <Center>
        <Column
          style={{
            maxWidth: 600,
            width: '100%',
          }}
        >
          <Header1>Ask a Question</Header1>
          <Spacer size={12} />
          <Text>
            Ask any question about {organization.name} condominiums.{' '}
            {process.env.COMPANY_NAME} will use information from your governing
            documents to answer your question. Please remember that answers
            provided by {process.env.COMPANY_NAME} are not a replacement for
            answers given by your property manager or board of directors.
          </Text>
          {promptResponse ? (
            <>
              <Spacer />
              <AnswerPane>
                <Header3 style={{ fontWeight: 500, lineHeight: '20px' }}>
                  {prompt}
                </Header3>
                <Spacer size={12} />
                <Markdown value={promptResponse.completion.response} />
                {showPromptSources && (
                  <>
                    <Text>
                      For more information, please see the following documents:
                    </Text>
                    {promptResponse.embeddings.map((embedding) => {
                      return (
                        <li key={embedding.token}>
                          <Tooltip
                            title={`${embedding.description} (${embedding.distance})`}
                          >
                            <a
                              href={`/api/files/${embedding.entityToken}/view`}
                              target="_"
                            >
                              {fileMap.get(embedding.entityToken)?.name ?? ''}
                            </a>
                          </Tooltip>
                        </li>
                      );
                    })}
                  </>
                )}
              </AnswerPane>
              <Spacer />
              <Button
                onClick={() => {
                  setPrompt('');
                  setPromptResponse(null);
                }}
                style={{
                  width: '220px',
                }}
              >
                <ArrowLeftOutlined /> Ask another question
              </Button>
            </>
          ) : (
            <>
              <Spacer />
              <Row gap={6}>
                <TextArea
                  allowClear
                  value={prompt}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      void handlePromptSubmit(prompt);
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  onChange={(e) => {
                    setPrompt(e.currentTarget.value);
                  }}
                  autoSize
                  placeholder="What do you need help with?"
                  style={{ padding: '4px 6px', height: 40 }}
                  disabled={loading}
                />
                <PrimaryButton
                  disabled={loading}
                  onClick={() => {
                    void handlePromptSubmit(prompt);
                  }}
                  style={{ width: 120, height: 42 }}
                >
                  Submit
                </PrimaryButton>
              </Row>
            </>
          )}
        </Column>
      </Center>
    </PageContent>
  );
};

const AnswerPane = styled(Pane)`
  padding: 18px 24px;
  background-color: #f5f5f5;
`;
