import { Feature } from '@shared/features';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { GrowingSpacer, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import * as React from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CompanyLogo } from './CompanyLogo';
import { ImpersonationBanner } from './ImpersonationBanner';
import { MobilePageHeader } from './MobilePageHeader';
import { PageUserProfile } from './PageUserProfile';
import { IF_MOBILE, useResponsive } from './responsive';
import { CustomerSidebar, InternalSidebar, SIDEBAR_WIDTH } from './sidebars';

export interface IPageProps {
  children: React.ReactNode;
}

export const PageContent: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <PageContentContainer style={style}>{children}</PageContentContainer>
);

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 24px 36px;

  ${IF_MOBILE} {
    padding-top: 0;
  }
`;

export const Page: React.FC<IPageProps> = ({ children }) => {
  const { booleanValue: announcementsEnabled } = useFeature(
    Feature.ANNOUNCEMENTS,
  );
  const { isMobile } = useResponsive();
  const { isSuper } = useAuth();

  return (
    <PageContainer>
      {isMobile ? (
        <MobilePageHeader
          menu={isSuper ? <InternalSidebar /> : <CustomerSidebar />}
        />
      ) : (
        <Topbar>
          <CompanyLogo />
          <Spacer size={36} />
          <TopbarLink to={`/chat`}>Ask a Question</TopbarLink>
          {announcementsEnabled && (
            <TopbarLink to={`/announcements`}>Announcements</TopbarLink>
          )}
          <TopbarLink to={`/governance`}>Governance</TopbarLink>
          <TopbarLink to={`/explore`}>Documents</TopbarLink>
          <GrowingSpacer />
          <PageUserProfile />
        </Topbar>
      )}
      <Layout>
        {isSuper && <InternalSidebar />}
        <RightSide>{children}</RightSide>
      </Layout>
      <ImpersonationBanner />
    </PageContainer>
  );
};

const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 36px;
  border-bottom: 1px solid #e0e0e0;

  .sidebar-link,
  .sidebar-title {
    cursor: pointer;
    border-radius: var(--default-border-radius);
    transition: all 0.2s linear;
    padding: 0 18px;
    white-space: nowrap;
    height: 36px;
    display: flex;
    align-items: center;

    > span {
      margin: 0;
      display: flex;
      gap: 12px;
      align-items: center;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #666;

      svg {
        font-size: 18px;
        padding: 2px;
        margin: 0;
        color: #666;
      }
    }
  }

  .sidebar-link:hover {
    background-color: #f5f5f5;
  }
`;

const TopbarLink: React.FC<{
  to?: string;
  url?: string;
  children: React.ReactNode;
}> = ({ to, url, children }) =>
  url ? (
    <a href={url} target="_blank" rel="noreferrer" className="sidebar-link">
      <Text>{children}</Text>
    </a>
  ) : (
    <NavLink to={to} className="sidebar-link">
      <Text>{children}</Text>
    </NavLink>
  );

const PageContainer = styled.div``;

const Layout = styled.div`
  display: flex;
  position: relative;

  > :first-child {
    min-width: ${SIDEBAR_WIDTH}px;
  }
`;

const RightSide = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--page-background);
  flex: 1;

  ${IF_MOBILE} {
    padding-top: 0;
  }
`;

export function usePage<
  ParamsType extends Record<string, string> = Record<string, string>,
>() {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const params = useParams<ParamsType>();

  return { navigate, navigateBack, ...params };
}
