import { FolderAddOutlined, SearchOutlined } from '@ant-design/icons';
import { searchFiles } from '@client/FilesClient';
import { IFile, IFolder, isRootFolder } from '@shared/files';
import { PageContent, usePage } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { Button } from '@web/components/Button';
import {
  Center,
  Column,
  GrowingSpacer,
  ResponsiveRow,
  Row,
  Spacer,
} from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Skeleton, Tooltip, message } from 'antd';
import Input from 'antd/es/input/Input';
import { debounce } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import { EditFolderModal } from './files/EditFolderModal';
import { FileList } from './files/FileList';
import { EmptyFolderContents, FolderContents } from './files/FolderContents';
import { UploadNewFile } from './files/UploadNewFile';
import { useFolder } from './files/useFolder';

const useFileSearch = () => {
  const [isSearching, setIsSearching] = React.useState(false);
  const [query, setQuery] = React.useState<string>(null);
  const [searchResults, setSearchResults] = React.useState<IFile[]>(null);
  const search = debounce(async (query: string) => {
    setIsSearching(true);

    try {
      const files = await searchFiles(query);
      setSearchResults(files);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSearching(false);
    }
  }, 200);
  React.useEffect(() => {
    if (query?.length > 2) {
      void search(query);
    } else {
      setSearchResults(null);
    }
  }, [query]);

  return { query, setQuery, search, searchResults, isSearching };
};

export const ExplorePage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { folderToken } = usePage<{ folderToken?: IFolder['token'] }>();
  const { setQuery, query, searchResults } = useFileSearch();
  const { data: folder, mutate: reloadFolder } = useFolder(folderToken);
  const [showNewFolder, setShowNewFolder] = React.useState(false);

  if (!folder) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <Center>
        <Column style={{ maxWidth: 1024, width: '100%' }}>
          <ResponsiveRow style={{ alignItems: 'flex-start' }}>
            <Header1>Documents</Header1>
            {isMobile ? <Spacer size={0} /> : <GrowingSpacer />}
            <Row
              style={{ width: isMobile ? '100%' : undefined, flexShrink: 0 }}
            >
              <Input
                placeholder="Search for a document..."
                prefix={<SearchOutlined />}
                style={{ width: isMobile ? undefined : 240, flex: 1 }}
                value={query}
                onChange={(e) => {
                  setQuery(e.currentTarget.value);
                }}
                allowClear
              />
              {!isMobile && (
                <>
                  <Spacer size={6} />
                  <ActionButtons>
                    <Tooltip title="Add folder">
                      <Button
                        onClick={() =>
                          setTimeout(() => {
                            setShowNewFolder(true);
                          }, 100)
                        }
                      >
                        <FolderAddOutlined />
                      </Button>
                    </Tooltip>
                    <UploadNewFile
                      onUpload={() => {
                        void reloadFolder();
                      }}
                      folderToken={folderToken}
                    />
                  </ActionButtons>
                </>
              )}
            </Row>
          </ResponsiveRow>
          {isMobile && <Spacer />}
          {searchResults && (
            <Column>
              <Text>
                Found {searchResults.length}{' '}
                {pluralize('document', searchResults.length)}
              </Text>
              <FileList files={searchResults} readonly />
            </Column>
          )}
          {!searchResults && (
            <FolderContents folder={folder} onChange={reloadFolder} />
          )}
          {!searchResults &&
            folder.folders.length === 0 &&
            folder.files.length === 0 && (
              <EmptyFolderContents
                onCreateFolderClicked={() => setShowNewFolder(true)}
                onChange={reloadFolder}
              />
            )}
          {showNewFolder && (
            <EditFolderModal
              parentFolderToken={
                isRootFolder(folder) ? undefined : folder?.token
              }
              onSave={() => {
                setShowNewFolder(false);
                void reloadFolder();
              }}
              onClose={() => {
                setShowNewFolder(false);
              }}
            />
          )}
        </Column>
      </Center>
    </PageContent>
  );
};

const ActionButtons = styled(Row).attrs({ gap: 6 })`
  .ant-btn svg {
    font-size: 16px;
  }
`;
