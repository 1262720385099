import { LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '@web/auth/useAuth';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, Row } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { Dropdown } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const PageUserProfile: React.FC = () => {
  const { user, organization, logout } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <PageUserProfileContainer>
      <Dropdown
        arrow
        placement="topCenter"
        menu={{
          items: [
            {
              icon: <LogoutOutlined />,
              label: 'Logout',
              onClick: logout,
              key: 'logout',
            },
          ],
        }}
      >
        <Row gap={6}>
          <UserAvatar user={user} size={24} />
          <Column>
            <Text style={{ lineHeight: '16px', fontWeight: '500' }}>
              {user.name}
            </Text>
            <Small style={{ lineHeight: '16px' }}>
              {organization?.name ?? process.env.COMPANY_NAME}
            </Small>
          </Column>
        </Row>
      </Dropdown>
    </PageUserProfileContainer>
  );
};

const PageUserProfileContainer = styled.section`
  cursor: pointer;
  padding: 6px 12px;
  border-radius: var(--default-border-radius);

  &:hover {
    background-color: #f5f5f5;
  }
`;
