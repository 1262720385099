import { LinkOutlined } from '@ant-design/icons';
import { useGoverningDocuments } from '@client/FilesClient';
import { ILink } from '@shared/documents';
import { PageContent } from '@web/app/Page';
import { Center, Column, Spacer } from '@web/components/layout';
import { Header1, Header2, Text } from '@web/components/typography';
import { FileIcon } from '@web/topics/FileIcon';
import { Skeleton } from 'antd';
import { last } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

export const GovernancePage: React.FC = () => {
  const { data: governingDocuments } = useGoverningDocuments();

  if (!governingDocuments) {
    return (
      <PageContent>
        <Center>
          <Column
            style={{
              maxWidth: 800,
              width: '100%',
            }}
            gap={24}
          >
            <Header1>Governance</Header1>
            <Skeleton />
          </Column>
        </Center>
      </PageContent>
    );
  } else if (governingDocuments.length === 0) {
    return (
      <PageContent>
        <Center>
          <Column
            style={{
              maxWidth: 800,
              width: '100%',
            }}
            gap={24}
          >
            <Header1>Governance</Header1>
            <Text>
              Condominium governance in Ontario is a structured system where
              multiple sources of authority work together to ensure the smooth
              operation of condo communities.
            </Text>
            <Text style={{ fontStyle: 'italic' }}>
              There have been no governing documents setup yet.
            </Text>
          </Column>
        </Center>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <Center>
        <Column
          style={{
            maxWidth: 800,
            width: '100%',
          }}
          gap={24}
        >
          <Column>
            <Header1>Governance</Header1>
            <Text>
              Condominium governance in Ontario is a structured system where
              multiple sources of authority work together to ensure the smooth
              operation of condo communities. Those sources include the
              following:
            </Text>
          </Column>
          {governingDocuments.map((document) => (
            <Column key={document.token}>
              <Header2>{document.title}</Header2>
              <Spacer size={6} />
              <Text>{document.description}</Text>
              <Spacer size={12} />
              {document.files?.map((file) => (
                <DocumentLink
                  key={file.token}
                  link={{
                    url: `/api/files/${file.token}/view`,
                    label: file.name,
                  }}
                  icon={
                    <FileIcon
                      extension={last(file.name.split('.'))}
                      size={20}
                    />
                  }
                  style={{ paddingLeft: 12 }}
                />
              ))}
              {document.links?.map((link, index) => (
                <DocumentLink
                  key={index}
                  link={link}
                  style={{ paddingLeft: 12 }}
                />
              ))}
            </Column>
          ))}
        </Column>
      </Center>
    </PageContent>
  );
};

const DocumentLink: React.FC<{
  link: ILink;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ link, icon = <LinkOutlined style={{ fontSize: 16 }} />, style }) => (
  <DocumentLinkContainer
    href={link.url}
    style={style}
    aria-label={`Open ${link.label} in new tab`}
  >
    {icon}
    <Text>{link.label}</Text>
  </DocumentLinkContainer>
);
const DocumentLinkContainer = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #222;
  height: 36px;
  border-left: 5px solid #666;
  background: #f5f5f5;

  ${Text} {
    font-size: 14px;
    font-weight: 500;
  }

  > ${Text}:hover {
    text-decoration: underline;
  }
`;
