import { AnnouncementToken, IAnnouncement } from '@shared/announcements';
import { del, get, patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useAnnouncements() {
  const url = stringifyUrl({
    url: `/announcements`,
    query: {},
  });

  return useApi<IAnnouncement[]>(url);
}

export function announcements() {
  const url = stringifyUrl({
    url: `/announcements`,
    query: {},
  });

  return get<IAnnouncement[]>(url);
}

export function createAnnouncement(title: string, content: string) {
  return post<{
    title: string;
    content: string;
  }>(`/announcements`, {
    title: title,
    content: content,
  });
}

export function updateAnnouncement(
  announcementToken: AnnouncementToken,
  title: string,
  content: string,
) {
  return patch<{
    title: string;
    content: string;
  }>(`/announcements/${announcementToken}`, {
    title: title,
    content: content,
  });
}

export function removeAnnouncement(announcementToken: AnnouncementToken) {
  return del(`/announcements/${announcementToken}`);
}

export function publishAnnouncement(announcementToken: AnnouncementToken) {
  return post<{}>(`/announcements/${announcementToken}/publish`);
}

export function useAnnouncement(announcementToken: AnnouncementToken) {
  const url = stringifyUrl({
    url: `/announcements/${announcementToken}`,
    query: {},
  });

  return useApi<IAnnouncement>(url);
}

export function announcement(announcementToken: AnnouncementToken) {
  const url = stringifyUrl({
    url: `/announcements/${announcementToken}`,
    query: {},
  });

  return get<IAnnouncement>(url);
}
